import { render, staticRenderFns } from "./About.vue?vue&type=template&id=3b13c182&scoped=true&"
var script = {}
import style0 from "./About.vue?vue&type=style&index=0&id=3b13c182&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b13c182",
  null
  
)

export default component.exports